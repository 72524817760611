import React, { useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { setActiveModalAction, updateUserData } from '../../actions';
import { EditableInput, Icon, IconTypes } from '../../components';
import { fromStore } from '../../selectors';
import {
  declOfNum, formatPhone, getAdress, numberDevider,
} from '../../utils';

export function ProfileCard() {
  const dispatch = useDispatch();
  const user = useSelector(fromStore.userSelector);
  const userUpdateLoader = useSelector(fromStore.userLoaderSelector);
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const onNavigate = (path) => () => {
    navigate(path);
  };

  const onOpenCityModal = () => {
    dispatch(setActiveModalAction({ field: 'city', value: true }));
  };

  const onOpenPointModal = () => {
    dispatch(setActiveModalAction({ field: 'point', value: true }));
  };

  const onAdressModal = () => {
    dispatch(setActiveModalAction({ field: 'adress', value: true }));
  };

  const onSaveName = (value) => {
    dispatch(updateUserData({ name: value }));
  };

  const onSaveBirthday = (value) => {
    const today = dayjs(new Date()).format('YYYY-MM-DD');

    if (value >= today) {
      return setError('birthday');
    }

    setError(null);
    const birthday = `${value}T12:00:00`;
    return dispatch(updateUserData({ birthday }));
  };

  return (
    <>
      <div className="card border-0 p-0 abs" style={{ top: '20px' }}>
        <div className="card-body p-0">

          <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
            <div className="me-7 mb-4">
              <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                <img src="/assets/media/avatars/kp.jpg" alt="image2" />
              </div>
            </div>

            <div className="flex-grow-1">
              <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-2">
                    <div className="text-gray-900 fs-2 fw-bolder me-1">{user.name || 'Пользователь'}</div>
                    <Icon type={IconTypes.verify} className="svg-icon svg-icon-1 svg-icon-primary" />
                  </div>

                  <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                    <div className="d-flex align-items-center text-gray-600 me-5 mb-2">
                      <Icon type={IconTypes.phone} className="svg-icon svg-icon-4 me-1" />
                      {formatPhone(user.phone)}
                    </div>
                  </div>

                </div>
              </div>

              <div className="d-flex flex-wrap flex-stack">

                <div className="d-flex flex-column flex-grow-1 pe-8">

                  <div className="d-flex flex-wrap">

                    <div className="border text-success  border-success border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div className="fs-2 fw-bolder">{numberDevider(user.balance)}</div>
                      <div className="fw-bold fs-6 text-gray-400">{declOfNum(user.balance, ['Бонус', 'Бонуса', 'Бонусов'], true)}</div>
                    </div>

                    <div aria-hidden onClick={onNavigate('/orders')} className="border bg-hover-light text-primary cursor-pointer border-hover-dark text-hover-dark border-primary border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div className="fs-2 fw-bolder">{user.orders ? user.orders.length : 0 }</div>
                      <div className="fw-bold fs-6 text-gray-400">{declOfNum(user.orders.length, ['Заказ', 'Заказа', 'Заказов'], true)}</div>
                    </div>

                    <div aria-hidden onClick={onNavigate('/favorites')} className="border bg-hover-light text-danger cursor-pointer border-hover-dark text-hover-dark border-danger border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div className="fs-2 fw-bolder">{user.favorites ? user.favorites.length : 0}</div>
                      <div className="fw-bold fs-6 text-gray-400">В избранном</div>
                    </div>
                  </div>

                </div>

                <div className="border bg-dark text-white border-dark border rounded min-w-125px py-3 px-4 me-6 mb-3">
                  <div className="fs-2 fw-bolder counted">{user.code}</div>
                  <div className="fw-bold fs-6">Реферальный код</div>
                </div>

              </div>

            </div>

          </div>
        </div>
      </div>

      <div className="card mt-10 mb-5 mb-xl-10" id="kt_profile_details_view">

        <div className="card-header">
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Детальная информация</h3>
          </div>
        </div>

        <div className="card-body p-9">
          <div className="row mb-5">
            <div className="col-lg-4 fw-bold text-muted d-flex align-items-center">Имя</div>
            <div className="col-lg-8">
              <EditableInput value={user.name} type="text" defaultValue="Пользователь" onSave={onSaveName} loader={userUpdateLoader} />
            </div>
          </div>

          <div className="row mb-5">
            {!user.birthday
              && (
              <span
                className="position-absolute badge badge-circle badge-danger"
                style={{
                  width: '0.5rem',
                  height: '0.5rem',
                  minWidth: '0.5rem',
                  marginTop: '16px',
                }}
              />
              )}
            <div className="col-lg-4 fw-bold text-muted d-flex align-items-center"> День рождения *</div>
            <div className="col-lg-8 gap-4 d-flex align-items-center flex-wrap">
              {user.birthday
                ? <span className="fw-bolder fs-6 text-gray-800 me-3">{dayjs(user.birthday).format('DD.MM.YYYY')}</span>
                : <EditableInput value={user.birthday ? dayjs(user.birthday).format('DD.MM.YYYY') : null} type="date" defaultValue="День рождения" onSave={onSaveBirthday} loader={userUpdateLoader} />}
              <span className="badge badge-light" style={{ whiteSpace: 'break-spaces' }}> * Для формирования персональных предложений</span>
              {error === 'birthday' && <span className="badge badge-danger" style={{ whiteSpace: 'break-spaces' }}> Некорректная дата </span>}
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-lg-4 fw-bold text-muted d-flex align-items-center">
              Телефон
            </div>
            <div className="col-lg-8 d-flex align-items-center">
              <span className="fw-bolder fs-6 text-gray-800 me-3">{formatPhone(user.phone)}</span>
              <span className="badge badge-success">Подтвержден</span>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-lg-4 fw-bold text-muted d-flex align-items-center">Город</div>
            <div className="col-lg-8 d-flex align-items-center">
              <div className="fw-bold fs-6 text-gray-800 text-hover-primary">
                {user.city ? user.city.name : 'Выбрать город'}
              </div>
              <button onClick={onOpenCityModal} type="button" className="btn btn-sm btn-icon btn-white btn-active-white ms-1 btn-hover-scale">
                <Icon type={IconTypes.edit} className="svg-icon svg-icon-3" />
              </button>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-lg-4 fw-bold text-muted d-flex align-items-center">
              Адрес
            </div>
            {user.street ? (
              <div className="col-lg-8 d-flex align-items-center">
                <span className="fw-bolder fs-6 text-gray-800">{getAdress(user)}</span>
                <button onClick={onAdressModal} type="button" className="btn btn-sm btn-icon btn-white btn-active-white ms-1 btn-hover-scale">
                  <Icon type={IconTypes.edit} className="svg-icon svg-icon-3" />
                </button>
              </div>
            ) : (
              <div className="col-lg-8 d-flex align-items-center">
                <button onClick={onAdressModal} type="button" className="btn btn-danger btn-sm py-2 btn-hover-rise">Добавить адрес доставки</button>
              </div>
            )}

          </div>

          <div className="row mb-5">
            <div className="col-lg-4 fw-bold text-muted d-flex align-items-center">Пункт самовывоза</div>
            {
              isEmpty(user.points) ? (
                <div className="col-lg-8 d-flex align-items-center">
                  <button onClick={onOpenPointModal} type="button" className="btn btn-danger btn-sm py-2 btn-hover-rise">Выбрать пункт самовывоза</button>
                </div>
              ) : (
                <div className="col-lg-8 d-flex align-items-center">
                  <span className="fw-bolder fs-6 text-gray-800">{getAdress(user.points[0])}</span>
                  <button onClick={onOpenPointModal} type="button" className="btn btn-sm btn-icon btn-white btn-active-white ms-1 btn-hover-scale">
                    <Icon type={IconTypes.edit} className="svg-icon svg-icon-3" />
                  </button>
                </div>
              )
            }

          </div>
          <div className="wrapper d-flex flex-column flex-row-fluid mt-5 mt-lg-10">
            <div className="content flex-column-fluid position-relative">
              <div className="toolbar d-flex flex-stack flex-wrap mb-5 mb-lg-7">
                <div className="page-title d-flex flex-column py-1">
                  <div className="fs-3">
                    Чтобы удалить учетную запись в приложении
                    {' '}
                    <span className="fw-bold ">SUSHI BOX - доставка роллов нужно</span>
                    :
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column">
                <div className="flex-lg-row-auto w-100">
                  <div className="card bg-light">
                    <div className="card-body">
                      <div className="">
                        <div className="my-2">
                          <div className="d-flex align-items-center mb-3">
                            <span className="bullet me-3" />
                            <div className="text-gray-600 fw-bold fs-4">Оставить запрос в форме обратной связи</div>
                          </div>
                          <div className="d-flex align-items-center mb-3">
                            <span className="bullet me-3" />
                            <div className="text-gray-600 fw-bold fs-4">Указать данные вашей учетной записи (Имя, телефон)</div>
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="bullet me-3" />
                            <div className="text-gray-600 fw-bold fs-4">После обработки запроса, мы удалим учетную запись</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
}
