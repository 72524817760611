import React from 'react';
import {
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { routesConfig } from '../constants';
import { AsideMenu } from '../containers';
import AboutPage from './AboutPage';
import BlogPage from './BlogPage';
import ContactsPage from './ContactsPage';
import FavoritesPage from './FavoritesPage';
import FranchisePage from './FranchisePage';
import HomePage from './HomePage';
import MenuPage from './MenuPage';
import NotificationsPage from './NotificationsPage';
import OrderPage from './OrderPage';
import OrdersPage from './OrdersPage';
import Page404 from './Page404';
import PolicyPage from './PolicyPage';
import UserPage from './UserPage';
import ArchivePage from './ArchivePage';

const pages = {
  home: HomePage,
  menu: MenuPage,
  blog: BlogPage,
  contacts: ContactsPage,
  franchise: FranchisePage,
  about: AboutPage,
  profile: UserPage,
  orders: OrdersPage,
  archive: ArchivePage,
  favorites: FavoritesPage,
  order: OrderPage,
  policy: PolicyPage,
  notifications: NotificationsPage,
};

export default function Pages() {
  const routes = Object.keys(routesConfig).map((route) => {
    const { href, nested } = routesConfig[route];
    const Component = pages[route];

    return <Route key={route} path={`${href}${nested ? '/*' : ''}`} element={<Component />} />;
  });

  return (
    <div className="d-flex flex-column-fluid align-items-stretch container-xxl position-relative">
      <AsideMenu />
      <Routes>
        {routes}
        <Route path="/404" element={<Page404 />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </div>
  );
}
