/* eslint-disable max-len */
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { setActiveModalAction } from '../../../actions';
import { PizzaSizer } from '../../../components';
import { useOnClickOutside } from '../../../hooks';
import { fromStore } from '../../../selectors';
import { AddCartButton, FavotiteButton, GoodPrice } from '../../card';
import {
  getIdModGood, getImageUrl, getModifiedGood, numberDevider,
} from '../../../utils';
import { MEASURE } from '../../../constants';

export function DetailModal() {
  const dispatch = useDispatch();
  const ref = useRef();

  const goodId = useSelector(fromStore.detailGoodModal);
  const good = useSelector(fromStore.goodsByIdSelector(goodId));
  const categoryIdPizza = useSelector(fromStore.getCategorySettingsId('pizza'));

  const isPizza = good.category.id === categoryIdPizza;

  const { modifications } = good;
  const defaultModification = !isEmpty(modifications)
    && (modifications.find((mod) => mod.default) || modifications[0]);

  const [activeMod, setActiveMod] = useState(defaultModification);

  const cartId = isPizza && activeMod ? getIdModGood(activeMod, goodId) : String(goodId);
  const onClose = () => dispatch(setActiveModalAction({ field: 'detail', value: null }));
  const item = activeMod ? getModifiedGood(good, activeMod.id) : good;

  useOnClickOutside(ref, onClose);

  const renderPizzaSizer = isPizza && activeMod && (
    <div className="d-flex">
      <div className="border border-gray-300 border-dashed rounded min-w-100px w-100 py-2 px-4 mb-3">
        <div className="d-flex flex-stack">
          <span className="fs-4 text-gray-600 me-5">Размер пиццы</span>
          <PizzaSizer
            items={good.modifications}
            selected={activeMod}
            onChange={(value) => setActiveMod(value)}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="modal fade d-block show">
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div ref={ref} className="modal-content">
          <div className="modal-body scroll-y p-0 m-0">
            <div className="card card-flush border-0 h-100">

              <div className="card-body py-9">
                <div className="row gx-9 h-100">

                  <div className="col-lg-6 mb-10 mb-lg-0">
                    <div className="rounded min-w-300px position-relative">
                      <img src={getImageUrl(good.image)} alt={good.name} className="rounded w-100" />
                    </div>
                  </div>

                  <div className="col-lg-6">

                    <div className="d-flex flex-column h-100">

                      <div>
                        <div className="d-flex flex-stack mb-5">
                          <div className="me-5">
                            <div className="d-flex align-items-center mb-2">
                              <span className="badge badge-secondary">{good.category.title}</span>
                              {good.hot && <img className="ms-3 w-20px" src="/assets/media/pepper.png" alt="острое" />}
                              {good.vegan && <img className="ms-3 w-20px" src="/assets/media/vegan.png" alt="веган" />}
                            </div>
                            <span className="text-gray-800 fs-1 lh-sm fw-bolder">{good.name}</span>
                          </div>

                          <button type="button" onClick={onClose} className="btn btn-light flex-shrink-0 align-self-start fs-7 px-2 py-2">
                            <span className="svg-icon svg-icon-muted svg-icon-1 me-0">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path opacity="0.3" d="M6 19.7C5.7 19.7 5.5 19.6 5.3 19.4C4.9 19 4.9 18.4 5.3 18L18 5.3C18.4 4.9 19 4.9 19.4 5.3C19.8 5.7 19.8 6.29999 19.4 6.69999L6.7 19.4C6.5 19.6 6.3 19.7 6 19.7Z" fill="currentColor" />
                                <path d="M18.8 19.7C18.5 19.7 18.3 19.6 18.1 19.4L5.40001 6.69999C5.00001 6.29999 5.00001 5.7 5.40001 5.3C5.80001 4.9 6.40001 4.9 6.80001 5.3L19.5 18C19.9 18.4 19.9 19 19.5 19.4C19.3 19.6 19 19.7 18.8 19.7Z" fill="currentColor" />
                              </svg>
                            </span>
                          </button>
                        </div>
                        <div className="d-flex align-items-center flex-wrap d-grid gap-2 mb-5">
                          <div className="d-flex align-items-center me-5">
                            <div className="symbol symbol-40px me-3">
                              <span className="symbol-label bg-light">
                                <span className="svg-icon svg-icon-1 svg-icon-gray-600">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.3" width="12" height="2" rx="1" transform="matrix(0 -1 -1 0 12.75 19.75)" fill="currentColor" />
                                    <path d="M12.0573 17.8813L13.5203 16.1256C13.9121 15.6554 14.6232 15.6232 15.056 16.056C15.4457 16.4457 15.4641 17.0716 15.0979 17.4836L12.4974 20.4092C12.0996 20.8567 11.4004 20.8567 11.0026 20.4092L8.40206 17.4836C8.0359 17.0716 8.0543 16.4457 8.44401 16.056C8.87683 15.6232 9.58785 15.6554 9.9797 16.1256L11.4427 17.8813C11.6026 18.0732 11.8974 18.0732 12.0573 17.8813Z" fill="currentColor" />
                                    <path d="M18.75 15.75H17.75C17.1977 15.75 16.75 15.3023 16.75 14.75C16.75 14.1977 17.1977 13.75 17.75 13.75C18.3023 13.75 18.75 13.3023 18.75 12.75V5.75C18.75 5.19771 18.3023 4.75 17.75 4.75L5.75 4.75C5.19772 4.75 4.75 5.19771 4.75 5.75V12.75C4.75 13.3023 5.19771 13.75 5.75 13.75C6.30229 13.75 6.75 14.1977 6.75 14.75C6.75 15.3023 6.30229 15.75 5.75 15.75H4.75C3.64543 15.75 2.75 14.8546 2.75 13.75V4.75C2.75 3.64543 3.64543 2.75 4.75 2.75L18.75 2.75C19.8546 2.75 20.75 3.64543 20.75 4.75V13.75C20.75 14.8546 19.8546 15.75 18.75 15.75Z" fill="#C4C4C4" />
                                  </svg>
                                </span>
                              </span>
                            </div>
                            <div className="m-0">
                              <span className="fw-bold text-gray-400 d-block fs-8">{MEASURE[good.measure].title}</span>
                              <span className="fw-bolder text-gray-800 fs-7">{`${numberDevider(item.weight)} ${MEASURE[good.measure].value}`}</span>
                            </div>
                          </div>
                          <FavotiteButton id={goodId} />
                        </div>
                      </div>

                      <div className="mb-6">
                        <span className="fw-bold text-gray-600 fs-6 mb-10 d-block">{good.description}</span>
                        {renderPizzaSizer}
                      </div>

                      <div className="d-flex flex-stack mt-auto bd-highlight">
                        <GoodPrice price={item.price} oldPrice={item.oldPrice} id={String(item.id)} />
                        <AddCartButton isStop={item.isStop} id={cartId} item={item} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
