/* eslint-disable no-unused-vars */
import axios from 'axios';
import {
  takeLatest, put, all, takeEvery, call,
} from 'redux-saga/effects';

import {
  loaderAction, fetchGoods, setGoods, setCategories, setEntities, addToFavoriteGoods,
} from '../actions';
import { restUrls } from '../constants';
import { normalizeItems, request, showNotification } from '../utils';
import { updateCurrentUser } from './auth';

const errorConfig = {
  title: 'Ошибка получения меню',
  text: 'Попробуйте позднее',
  icon: 'error',
  confirmButtonText: 'Повторить',
};

function* fetchCategoriesHandler() {
  try {
    const categories = yield call(request, { method: 'get', url: `${restUrls.categories}/listSite` });
    const nml = normalizeItems(categories, 'categories');
    yield put(setEntities(nml.entities));
    yield put(setCategories(nml.result));
    return true;
  } catch (error) {
    return false;
  }
}

function* fetchGoodsHandler() {
  try {
    const goods = yield call(request, { method: 'get', url: `${restUrls.goods}`, params: { active: true } });
    const nml = normalizeItems(goods, 'goods');
    yield put(setEntities(nml.entities));
    yield put(setGoods(nml.result));
    return true;
  } catch (error) {
    return false;
  }
}

export function* onFetchGoods() {
  yield put(loaderAction({ field: 'goods', value: true }));

  const result = yield all([
    fetchCategoriesHandler(),
    fetchGoodsHandler(),
  ]);

  if (result.some((item) => !item)) {
    const confirm = yield showNotification(errorConfig);
    if (confirm.isConfirmed) {
      yield* onFetchGoods();
    }
  }

  yield put(loaderAction({ field: 'goods', value: false }));
}

function* updateFavorite({ payload: { favorite, id } }) {
  yield put(loaderAction({ field: 'favorite', value: id }));
  yield updateCurrentUser({ favorites: favorite });
  yield put(loaderAction({ field: 'favorite', value: null }));
}

export default [
  takeLatest(fetchGoods, onFetchGoods),
  takeEvery(addToFavoriteGoods, updateFavorite),
];
