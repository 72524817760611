import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { numberDevider } from '../../utils';
import { fromStore } from '../../selectors';
import { closeCart } from '../../actions';
import { RecomendedGoods } from '../RecomendedGoods';
import { GiftGoods } from '../GiftGoods';

export function CartFooter() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const totalCost = useSelector(fromStore.totalGoodsPriceSelector);

  const onClose = () => dispatch(closeCart());

  const onSubmit = () => {
    navigate('/order');
    window.ym(92424510, 'reachGoal', 'order-start');
    onClose();
  };

  const renderTotal = (
    <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
      <tbody className="fw-bold text-gray-600">
        <tr>
          <td colSpan="4" className="fs-3 text-dark text-end">ИТОГО</td>
          <td className="text-dark fs-3 fw-boldest text-end">{`${numberDevider(totalCost)} ₽`}</td>
        </tr>
      </tbody>
    </table>
  );

  return (
    <div className="card-footer pt-0">
      <GiftGoods />
      <RecomendedGoods />
      {renderTotal}
      <div className="d-flex flex-stack align-items-center border-top pt-7">
        <button onClick={onClose} className="btn btn-light" type="button" data-kt-element="send">Отмена</button>
        <div className="d-flex">
          <button onClick={onSubmit} className="btn btn-danger btn-hover-scale" type="button" data-kt-element="send">Оформить заказ</button>
        </div>
      </div>
    </div>
  );
}
