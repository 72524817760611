import React, {
  useCallback,
  useMemo, useRef, useState,
} from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { v4 as uuid } from 'uuid';
import { isEmpty, isEqual } from 'lodash';
import {
  addWokToCart, deleteWokFromCart, openCart, setActiveModalAction,
} from '../../../actions';
import { useOnClickOutside } from '../../../hooks';
import { fromStore, settingsSelector } from '../../../selectors';
import { Icon, IconTypes } from '../../../components';
import { showNotification } from '../../../utils';
import { SelectToppings } from './SelectToppings';
import { ToppingRows } from './ToppingRows';

const notifyConfig = {
  icon: 'success',
  text: 'Ваш Wok добавлен в корзину',
  confirmButtonText: 'В корзину',
  showCancelButton: true,
  cancelButtonText: 'Продолжить',
  reverseButtons: true,
  customClass: {
    confirmButton: 'btn btn-primary',
    cancelButton: 'btn btn-light',
  },
};

export function WokModal() {
  const dispatch = useDispatch();
  const ref = useRef();

  const wokListCart = useSelector(fromStore.wokListCartSelector);
  const main = useSelector(fromStore.mainWokSelector);
  const souce = useSelector(fromStore.souceWokSelector);
  const meat = useSelector(fromStore.meatWokSelector);
  const toppings = useSelector(fromStore.toppingsWokSelector);
  const { wokDescr } = useSelector(settingsSelector);

  const [confirmSweet, setConfirm] = useState(false);
  const [selectedMain, setSelectedMain] = useState(main[0] || {});
  const [selectedSouce, setSelectedSouce] = useState(souce[0] ? [souce[0]] : []);
  const [selectedMeat, setSelectedMeat] = useState({});
  const [selectedToppings, setSelectedToppings] = useState({});

  const vegitables = wokDescr || 'Морковь, паприка, капуста, огурец';

  const hash = useMemo(() => {
    const check = {
      [selectedMain.id]: 1,
    };
    selectedSouce.forEach((item) => {
      check[item.id] = 1;
    });
    if (!isEmpty(selectedMeat)) {
      Object.values(selectedMeat).forEach((item) => {
        check[item.id] = item.count;
      });
    }
    if (!isEmpty(selectedToppings)) {
      Object.values(selectedToppings).forEach((item) => {
        check[item.id] = item.count;
      });
    }
    return check;
  }, [selectedMain, selectedSouce, selectedMeat, selectedToppings]);

  const itemInCard = useMemo(() => Object
    .values(wokListCart)
    .find((item) => isEqual(item.hash, hash)), [wokListCart, hash]);

  const id = useMemo(() => {
    if (isEmpty(wokListCart)) return uuid();
    if (itemInCard) return itemInCard.id;
    return uuid();
  }, [wokListCart, itemInCard]);

  const totalPrice = useMemo(() => {
    const mainPrice = selectedMain.price || 0;
    const soucePrice = selectedSouce.reduce((acc, item) => acc + item.price, 0);
    const meatPrice = Object.values(selectedMeat)
      .reduce((acc, item) => acc + (item.price * item.count), 0);

    const toppingsPrice = Object.values(selectedToppings)
      .reduce((acc, item) => acc + (item.price * item.count), 0);

    return mainPrice + soucePrice + meatPrice + toppingsPrice;
  }, [selectedMain, selectedSouce, selectedMeat, selectedToppings]);

  const hasReset = useMemo(() => {
    if (!isEmpty(selectedMeat) || !isEmpty(selectedToppings) || selectedSouce.length > 1) {
      return true;
    }

    return false;
  }, [selectedMeat, selectedSouce, selectedToppings]);

  const onClose = () => dispatch(setActiveModalAction({ field: 'wok', value: false }));

  const onSelectSouce = (items) => {
    if (items.length >= 1 && items.length < 3) {
      setSelectedSouce(items);
    }
  };

  const reset = () => {
    setSelectedSouce([souce[0]]);
    setSelectedMeat({});
    setSelectedToppings({});
  };

  const deleteSouce = (item) => () => {
    setSelectedSouce(selectedSouce.filter((selected) => selected.id !== item.id));
  };

  const createWokData = useCallback(() => ({
    id,
    hash,
    main: selectedMain,
    meat: selectedMeat,
    souce: selectedSouce,
    toppings: selectedToppings,
    price: totalPrice,
    image: '/assets/media/wokk.png',
  }), [id, hash, selectedMain, selectedMeat, selectedSouce, selectedToppings, totalPrice]);

  const addToCard = async () => {
    const wok = createWokData();
    dispatch(addWokToCart(wok));

    window.ym(92424510, 'reachGoal', 'add-dish');

    window.dataLayer.push(
      {
        ecommerce: {
          currencyCode: 'RUB',
          add: {
            products: {
              id: wok.id,
              brand: 'Sushibox',
              category: 'Лапша ВОК',
              price: wok.price,
            },
          },
        },
      },
    );

    if (!itemInCard) {
      const confirm = await showNotification({
        ...notifyConfig,
        didOpen: () => setConfirm(true),
        didClose: () => setConfirm(false),
      });
      if (confirm.isConfirmed) {
        onClose();
        dispatch(openCart(true));
      }
    }
  };

  const deleteFromCard = () => {
    const wok = createWokData();
    dispatch(deleteWokFromCart(wok));
  };

  const renderButtons = () => {
    if (itemInCard) {
      return (
        <div aria-hidden className="position-relative">
          <button onClick={deleteFromCard} type="button" className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 start-0">
            <Icon type={IconTypes.deleteCart} className="svg-icon svg-icon-2x" />
          </button>
          <input type="text" className="form-control h-45px fs-3 text-center sb-counter-bottom form-control-solid border-0" readOnly="readonly" value={itemInCard.count} />
          <button onClick={addToCard} type="button" className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 end-0">
            <Icon type={IconTypes.addCart} className="svg-icon svg-icon-2x" />
          </button>
        </div>
      );
    }

    return (
      <button disabled={!totalPrice} type="button" onClick={addToCard} className="btn btn-danger btn-hover-scale">
        <Icon type={IconTypes.plus} className="svg-icon svg-icon-1" />
        В корзину
      </button>
    );
  };

  useOnClickOutside(ref, () => !confirmSweet && onClose());

  const renderConstructor = (
    <div className="d-flex flex-column h-100">
      <div className="mb-8 mt-0">
        <div className="d-flex align-items-center form-label mb-3">
          Выберите основу
        </div>
        <Select
          value={isEmpty(selectedMain) ? null : selectedMain}
          className="react-select-container"
          classNamePrefix="react-select"
          options={main}
          placeholder="Выберите основу"
          onChange={(item) => setSelectedMain(item)}
          getOptionLabel={(item) => item.title}
          getOptionValue={(item) => item.id}
          isClearable={false}
          noOptionsMessage={() => 'Нет основ'}
        />
        <span className="badge badge-success badge-warp">
          {`Овощи: ${vegitables.toLowerCase()}`}
        </span>
      </div>

      <div className="mb-8 mt-0">
        <div className="d-flex align-items-center form-label mb-3">
          Выберите соус
          <i
            className="fas fa-exclamation-circle ms-2 fs-7"
            data-tip="Выберите от 1 до 2-х соусов"
          />
        </div>
        <Select
          value={selectedSouce}
          className="react-select-container"
          classNamePrefix="react-select"
          options={souce}
          placeholder="Выберите соус"
          onChange={onSelectSouce}
          getOptionLabel={(item) => item.title}
          getOptionValue={(item) => item.id}
          isMulti
          isClearable={false}
          noOptionsMessage={() => 'Нет соусов'}
        />
      </div>
      <SelectToppings title="Добавьте мясо/рыбу" items={meat} selected={selectedMeat} onSelect={setSelectedMeat} />
      <SelectToppings
        title="Добавьте топпинги"
        items={toppings}
        selected={selectedToppings}
        onSelect={setSelectedToppings}
      />
      {
        hasReset && (
          <div className="mt-auto mb-7">
            <button onClick={reset} type="button" className="btn btn-light">
              Сбросить
            </button>
          </div>
        )
      }
    </div>
  );

  const renderHeader = (
    <div className="d-flex flex-stack mb-7">
      <div className="me-5">
        <span className="text-gray-800 fs-1 lh-sm fw-bolder">Собери свой WOK</span>
      </div>
      <button type="button" onClick={onClose} className="btn btn-light flex-shrink-0 align-self-start fs-7 px-2 py-2">
        <Icon type={IconTypes.modalClose} className="svg-icon svg-icon-muted svg-icon-1 me-0" />
      </button>
    </div>
  );

  const renderSummary = (
    <div className="p-7 border rounded d-flex flex-column align-items-stretch h-100">
      <div className="w-100 text-center position-relative">
        <img src="/assets/media/wokk.png" alt="Мой вок" className="rounded w-200px" />
      </div>
      <div className="fs-1 mt-5 text-center">Мой WOK</div>
      <div className="table-responsive mt-5 mb-10 scroll-y min-h-200px mh-300px me-n3">
        <table className="table align-middle table-row-dashed fs-6 fw-bold gy-1 dataTable no-footer" id="kt_subscription_products_table">
          <tbody className="text-gray-800">
            {!isEmpty(selectedMain) && (
            <tr>
              <td>{selectedMain?.title}</td>
              <td>
                <span className="text-muted me-1">x</span>
                <span>1</span>
              </td>
              <td className="text-end">{`${selectedMain?.price} ₽`}</td>
              <td><div className="w-30px h-30px" /></td>
            </tr>
            )}
            <tr>
              <td>Овощи</td>
              <td>
                <span className="text-muted me-1">x</span>
                <span>1</span>
              </td>
              <td className="text-end">0 ₽</td>
              <td><div className="w-30px h-30px" /></td>
            </tr>
            {selectedSouce.map((item) => (
              <tr>
                <td>{`Соус ${item.title}`}</td>
                <td>
                  <span className="text-muted me-1">x</span>
                  <span>1</span>
                </td>
                <td className="text-end">{`${item.price} ₽`}</td>
                <td className="text-end">
                  {selectedSouce.length > 1 ? (
                    <button onClick={deleteSouce(item)} type="button" className="btn btn-icon btn-flex btn-active-light-primary w-30px h-30px">
                      <Icon type={IconTypes.trash} className="svg-icon svg-icon-3" />
                    </button>
                  ) : <div className="w-30px h-30px" />}
                </td>
              </tr>
            ))}
            <ToppingRows selectedItems={selectedMeat} onDelete={setSelectedMeat} />
            <ToppingRows selectedItems={selectedToppings} onDelete={setSelectedToppings} />
          </tbody>
        </table>
      </div>
      <div className="d-flex flex-stack mt-auto bd-highlight">
        <div className="fs-1 fw-bolder">{`${totalPrice} ₽`}</div>
        {renderButtons()}
      </div>
    </div>
  );

  return (
    <div className="modal fade d-block show">
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div ref={ref} className="modal-content">
          <div className="modal-body scroll-y p-0 m-0">
            <div className="card card-flush border-0 h-100">
              <div className="card-body py-9">
                {renderHeader}
                <div className="row gx-9 h-100">
                  <div className="col-lg-6 col-sm-12">
                    {renderConstructor}
                  </div>
                  <div className="col-lg-6 col-sm-12 mb-10 mb-sm-0">
                    {renderSummary}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactTooltip
        effect="solid"
        type="light"
        borderColor="#ccc"
        border
        place="top"
        className="tooltip-custom"
      />
    </div>
  );
}
