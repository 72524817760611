import React, { useEffect } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import { initAction } from './actions';
import { Loader } from './components';
import {
  CartBar, Footer, Header, Modals,
} from './containers';
import { Pages } from './pages';
import { fromStore } from './selectors';
import { persistor } from './store';
import { CookieNotify } from './components/CookieNotify';
import Effects from './components/Effects';

function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const loader = useSelector(fromStore.globalLoaderSelector);
  const path = location.pathname.replace(/\/+/g, '').trim();
  const wrapperClassName = cn(
    'page d-flex flex-column flex-column-fluid',
    { 'bg-gray-100': path === 'order' },
  );

  useEffect(() => {
    dispatch(initAction());
  }, []);

  if (loader) {
    return (
      <div className="page-loading-enabled page-loading ">
        <Loader />
      </div>
    );
  }

  return (
    <PersistGate loading={<Loader />} persistor={persistor}>
      <div className={wrapperClassName}>
        <Effects />
        <Header />
        <Pages />
        <Modals />
        <CartBar />
        <CookieNotify />
        <Footer />
      </div>
    </PersistGate>
  );
}

export default App;
